import React from 'react'
import styled from '@emotion/styled'
import Seo from '../../components/seo/seo';

const MainForPages = styled.main`
	max-width: 42rem;
	margin: 1rem auto;
	padding: 20px;
	grid-template-columns: 1fr;
`

const Disclaimer = () => (
<MainForPages>
	<Seo 
		title="Disclaimer - anonimoconiglio.com" 
		description="Disclaimer del blog anonimoconiglio.com | Qui si combattono gli insulti all'intelligenza, si deridono i fanatismi di qualunque tipo" 
		slug="questo-blog-e-un-luogononsense" 
	/>
	
		<div>
			<h1>Info - Disclaimer</h1>
			<p>
				Questo blog è un <b>luogo</b>
				<b>nonsense</b> personale, <b>critico</b> e <b>satirico</b> che riflette i pensieri
				malsani, immaginari o reali del suo autore. Può contenere di tutto,
				puoi starne alla larga. Al suo interno viene spesso applicato uno
				<b>stile corrosivo</b> nei confronti di qualunque argomento.</p>
			<p>
				Qui si combattono gli
				insulti all'intelligenza, si deridono i fanatismi di qualunque tipo,
				si coltiva il <i><b>polemos¹</b></i>: Il confronto accesso di idee, il conflitto
				avviato attraverso l'esercizio della critica in ogni sua variante,
				sia questa demolitrice, satirica, sarcastica, costruttiva e non.</p>
			<p>
				Vorrei davvero chiarire
				questo punto:</p>
			<p>
				<blockquote >
					<b>La critica è tale</b>. Sarà
				costruttiva nella misura in cui <b>tu </b>riuscirai a cavarne qualcosa di
				costruttivo.</blockquote>
			</p>
			<p>
				Essa è garantita dall'<b>Articolo 21 della Costituzione</b>, da cui deriva il <b>Diritto di Critica</b>,
				dove non viene affatto specificato che debba essere costruttiva.<b>*</b></p>
			<p>
				<i>La
				critica è dissenso ragionato</i></p>
			<p>
				Ma cazzo, diciamocelo. Il
				dissenso senza l'ironia e il sarcasmo sono come il cibo senza il
				sale, e dice il saggio – nel mio immaginario un Andrea
				Diprè con la barba - che <i>se non puoi combatterli devi riderci sopra, coniglio</i>.
				</p>
			<p>
				Quindi, fortunatamente
				le precedenti considerazioni
				sulla libertà di manifestazione del
				pensiero si amplificano se al <b>Diritto di
				Critica</b> aggiungiamo
				il <b>D</b><b>iritto di Satira</b>,
				che per sua natura è esento da dover rispettare certe
				regole, come la fottuta e ipocrita continenza linguistica «
				tanto per fare un esempio.</p>
			<p>
				<b>La satira</b> oltretutto non
				solo è garantita dal Articolo 21 della Costituzione, bensì pure
				dall'articolo 9 e dall'articolo 33. Essendo essa considerata una
				<b>forma d'arte</b>.</p>
			<p>
				E se per caso non bastasse
				faccio presente che nella giornata del 24 aprile 2008, giornata
				piovosa, me lo ricordo bene, <a href="http://www.legge-e-giustizia.it/index.php?option=com_content&amp;task=view&amp;id=3119&amp;Itemid=143" target="_blank" rel="noopener noreferrer">la Cassazione ha sancito che </a></p>
			<p>
				<blockquote >
					"<b>La satira costituisce
					una modalità corrosiva e spesso impietosa di esercizio del diritto di critica</b><i> e può realizzarsi anche mediante l'immagine artistica come accade per la vignetta o per la caricatura, consistenti nella consapevole ed accentuata alterazione dei tratti somatici, morali e comportamentali delle persone ritratte. persamente dalla cronaca, </i><b>la satira è sottratta al parametro della verità in quanto esprime mediante il paradosso e la metafora surreale un giudizio ironico su un fatto</b><i> ma rimane assoggettata al limite della continenza e della funzionalità delle espressioni o delle immagini rispetto allo </i><b>scopo di denuncia sociale o politica</b><i> perseguito. Conseguentemente, nella formulazione del giudizio critico, </i><b>possono essere utilizzate espressioni di qualsiasi tipo </b><i>anche lesive della reputazione altrui, </i><b>purché siano</b><i> strumentalmente collegate alla </i><b>manifestazione di un dissenso ragionato</b><i> dall'opinione o comportamento preso di mira e non si risolvono in un'aggressione gratuita e distruttiva dell'onore e della reputazione del soggetto interessato. La valutazione del limite della continenza e della funzionalità dell'immagine e dell'espressione usata nel titolo costituisce espressione del potere del giudice di merito di valutare i fatti a lui sottoposti."</i></blockquote>
			</p>
			<p>
				<b>Ordunque</b>, ricapitoliamo
				che mi sono già rotto il cazzo:</p>
			<p>
				Questo blog <i>non
				rappresenta una testata giornalistica, in quanto viene aggiornato
				senza alcuna periodicità. Non può, pertanto, considerarsi un
				prodotto editoriale, ai sensi della legge n. 62 del 7/03/2001
				</i></p>
			<p>
				Qui non si fa
				disinformazione, poiché in partenza non si ha la pretesa di fare
				informazione. Qui, se mai, si va oltre l'informazione, prendendo i
				fatti – a volte addirittura creandoli, alterandoli per esagerazione
				-, commentandoli, ironizzandoci sopra e discutendone insieme nei
				commenti.
				</p>
			<p>
				<br />
				{/* <a href="http://www.blogger.com/blogger.g?blogID=4509263638429913010" name="Disclaimer-Commenti"></a> */}
				<br />
				<p>
					<i name="Disclaimer-Commenti">Patti chiari amicizia lunga</i></p>
				<br /></p>
			<p>
				*<b>Attenzione</b>, le
				considerazioni sull'Art. 21 non comportano la libertà di insultare
				gratuitamente, pertanto in questo blog non
				si intende o si invita ad attaccare la sfera privata dei soggetti, bensì i
				“personaggi”, il potere e le idee che rappresentano. I
				lettori sono invitati a tenere ben presente questo punto e qualora
				non lo rispettassero tale mancanza non sarà responsabilità del gestore
				del blog (oltre al fatto che il sottoscritto si riserva la possibilità di moderarli).</p>
			<p>
				¹ Si coltiva il <i>polemos</i> perché coltivare
				marijuana è ancora illegale. Altrimenti state pur certi che non sarei qui a scrivere tutto questo.<br />
				<br />
				-------------------------------------------------<br />
				PS: La licenza del blog è Creative Commons, trovate più informazioni specifiche in basso.&nbsp;Alcune delle immagini pubblicate sono tratte da internet e valutate di pubblico dominio: qualora il loro uso violasse diritti d’autore,&nbsp;<a href="http://anonimoconiglio.blogspot.com/p/angolo-di-coniglio.html" target="_blank" rel="noopener noreferrer">lo si comunichi all’autore del Blog</a>&nbsp;che provvederà alla loro pronta rimozione.<br />
				<p>
				</p>
			</p>
		</div>
</MainForPages>

)

export default Disclaimer
